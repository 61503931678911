<template>
  <div>
    <el-row class="content-row">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            clearable
            v-model="search.vendor_id"
            placeholder="供应商"
            filterable
          >
            <el-option
              v-for="item in sysConfig.vendorList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请日期">
          <el-date-picker
            v-model="search._date"
            @change="setDate()"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            style="width:80px;"
            v-model="search.currency"
            placeholder="币种"
          >
            <el-option
              v-for="item in sysConfig.Common.currency"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPrepaymentList"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="step === '2'" size="small"
            >付款完成</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table stripe style="width:100%" :data="prepaymentlist" border>
        <el-table-column prop="id" label="请款单"></el-table-column>
        <el-table-column prop="vendorName" label="供应商"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="created_at" label="申请日期"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="pushorderDetail(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="pagination">
      <el-pagination
        :current-page.sync="pageDat.current_page"
        :page-size="parseInt(pageDat.per_page)"
        :page-sizes="[10, 20, 30, 50]"
        layout="total,prev, pager, next,sizes,jumper"
        :total="pageDat.total"
        @current-change="changePageHandler"
        @size-change="changeSizeHandler"
      ></el-pagination>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as cashOutApi from '@/api/cashOut'
export default {
  props: ['step'],
  data () {
    return {
      search: {},
      prepaymentlist: [],
      pageDat: { per_page: 10, current_page: 1 }
    }
  },
  computed: {
    ...mapGetters(['sysConfig']),
    // 请款单列表接口参数
    listJsondata () {
      return {
        step: this.step,
        object_id: this.search.vendor_id,
        currency: this.search.currency,
        data_start: this.search.start,
        data_end: this.search.end,
        page: this.pageDat.current_page,
        num_per_page: this.pageDat.per_page
      }
    }
  },
  watch: {
    step: function () {
      this.getPrepaymentList()
    }
  },
  created () {
    this.getPrepaymentList()
  },
  methods: {
    // 设置日期
    setDate () {
      if (this.search._date === null) {
        this.search.start = ''
        this.search.end = ''
      } else {
        this.search.start = this.search._date[0]
        this.search.end = this.search._date[1]
      }
    },
    getPrepaymentList () {
      console.log(this.listJsondata)
      cashOutApi
        .getprepaymentOrder(this.listJsondata)
        .then(data => {
          this.prepaymentlist = data.data
          this.pageDat.total = data.total
        })
        .catch(err => {
          console.log(err)
        })
    },
    changePageHandler (currentPage) {
      this.pageDat.current_page = currentPage
      this.getPrepaymentList()
    },
    changeSizeHandler (size) {
      this.pageDat.per_page = size
      this.getPrepaymentList()
    },
    pushorderDetail (orderid = null) {
      if (orderid) {
        this.$router.push('/finance/purchase/expenses-requisition/' + orderid)
      }
    }
  }
}
</script>
